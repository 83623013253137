const GOOGLE_API_KEY = "AIzaSyCAJenhvW04vcElA7ZUcdY1bR1hd7beP88";
const GOOGLE_API = "https://www.google.com/maps/search/?api=1&query=";
const googleEventApiUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initEventMap&loading=async`;
const svgPin = (color = "black") =>
  `./../../.././src/assets/images/pin/${color}.svg`;
function position(lat, lng) {
  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
}

//#region Google Map options
const googleMapOptions = {
  zoom: 4,
  center: { lat: 39.8097343, lng: -98.5556199 },
  //   restriction: {
  //     latLngBounds: {
  //       north: 49.382808,
  //       south: 24.521208,
  //       east: -66.945392,
  //       west: -124.736342,
  //    },
  //   },
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: true,
  fullscreenControl: false,
};
//#endRegion

function addEventScript(url) {
  //check if script already added
  if (document.head.querySelector(`script#google-event-map-script`) != null) return;
  const mapScript = document.createElement("script");
  mapScript.id = "google-event-map-script";
  mapScript.src = url;
  mapScript.async = true;
  document.head.appendChild(mapScript);
}

const mapElem = document.getElementById("eventsPg__googleMap");
const markerElems = document.querySelectorAll("li dialog [data-google-map]");

if (mapElem != null) {
    window.addEventListener("DOMContentLoaded", addEventScript(googleEventApiUrl));

}
window.initEventMap = function () {
    console.log("events page")
    if (markerElems.length && mapElem) {
    showGoogleMap();
  }
};

function showGoogleMap() {
  const experianceCenterMap = new google.maps.Map(mapElem, googleMapOptions);
  Array.from(markerElems).forEach((item) => {
    const { uniqueId, latitude, longitude, pinColor } = item.dataset;
    const modalMap = new google.maps.Map(item, {
      ...googleMapOptions,
      center: position(latitude, longitude),
    });
    const modalMarker = new google.maps.Marker({
      map: modalMap,
      position: position(latitude, longitude),
      icon: svgPin(pinColor),
    });
    const modal = document.getElementById(`eModal-${uniqueId}`);
    const marker = new google.maps.Marker({
      map: experianceCenterMap,
      position: position(latitude, longitude),
      icon: svgPin(pinColor),
    });
    marker.addListener("click", () => {
      modal.showModal();
      modal.scroll({ top: 0 });
      modal
        .querySelector("span.absolute")
        .addEventListener("click", () => modal.close(), { once: true });
    });
  });
}
