import _ from "underscore";

const $masthead = document.getElementById("masthead");

let mastheadHeight = $masthead.offsetHeight;
let prevScrollPos = window.scrollY;
let scrollBelowMasthead = false;
let scrollingUp = false;
let mastheadFixed = false;
let mastheadSlideIn = false;

var windowResize = _.debounce(() => {
  mastheadHeight = $masthead.offsetHeight;
}, 500);

window.addEventListener("resize", windowResize);

function handleScroll() {
  const currentScrollPos = window.scrollY;

  // Check if scroll height is greater than masthead height
  if (currentScrollPos >= (mastheadHeight ? mastheadHeight : 80)) {
    if (!mastheadFixed) {
      $masthead.classList.add("mastheadFixed");
      mastheadFixed = true;
    }
  } else {
    if (currentScrollPos <= 0) {
      $masthead.classList.remove("mastheadFixed");
      $masthead.classList.remove("slide-out");
      $masthead.classList.remove("slide-in");
      // document.documentElement.classList.remove("vertical-gallery-snap");
      mastheadFixed = false;
      mastheadSlideIn = false;
    }
  }

  // Check if scrolling up or down
  if (currentScrollPos < prevScrollPos) {
    // Scrolling up
    scrollingUp = true;
    if (mastheadFixed && !mastheadSlideIn) {
      //add the slide in class when scrolling up
      $masthead.classList.remove("slide-out");
      $masthead.classList.add("slide-in");
      mastheadSlideIn = true;
    }
  } else {
    // Scrolling down
    scrollingUp = false;

    if (mastheadFixed && mastheadSlideIn) {
      $masthead.classList.remove("slide-in");
      $masthead.classList.add("slide-out");
      mastheadSlideIn = false;
    }
  }

  // Update scrollPosition
  prevScrollPos = currentScrollPos;
}

window.addEventListener('scroll',handleScroll)